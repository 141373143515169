import React from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "./carousel.css";

// Check is mobile
import isMobile from "ismobilejs";

// Components
import ThreeTwo from "../images/three-two";

const GalleryContainer = styled.div`
  position: relative;
  opacity: ${props => props.opacity};

  width: 100%;
  height: calc(100% - 36px);

  padding: 36px 0 0 0;

  & .image-gallery,
  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .slider,
  & .slide {
    height: 100%;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;
  }
`;

const ImageContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;

  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "transparent"};

  & img {
    object-fit: contain;
    padding: 100px;
  }
`;

const FullBleedImageContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;

  & img {
    object-fit: contain;
  }
`;

const VideoContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;

  & video {
    width: auto;
    height: auto;

    width: 100%;
    height: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    z-index: 1;

    padding: ${props => props.padding}px;
  }
`;

const Video = styled.video`
  width: auto;
  height: auto;

  width: 100%;
  height: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  object-fit: cover;
  object-position: center;

  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  z-index: 1;
`;

const FullBleedImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  object-fit: cover !important;

  width: 100vw !important;
  height: calc(100vh - 36px - 36px);
`;

class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      allImages: [],
      activeSlide: null,
      totalSlides: 1,
    };
  }

  componentDidMount(props) {
    const slideTotal = this.props.images.length;
    const isMobileDevice = isMobile(window.navigator.userAgent).any ? 150 : 0;

    this.setState(
      {
        allImages: this.props.images,
        totalSlides: slideTotal,
        transitionTime: isMobileDevice,
      },
      () => {
        let activeSlideDetails = this.state.allImages[this.state.currentSlide]
          .project.document.data.title;

        let activeSlideUid = this.state.allImages[this.state.currentSlide]
          .project.document.uid;

        this.props.setActiveProjectText(activeSlideDetails);
        this.props.setActiveProjectUid(activeSlideUid);
      }
    );
  }

  updateCurrentSlide = index => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState(
        {
          currentSlide: index,
        },
        () => {
          let activeSlideDetails = this.state.allImages[this.state.currentSlide]
            .project.document.data.title;
          let activeSlideUid = this.state.allImages[this.state.currentSlide]
            .project.document.uid;

          this.props.setActiveProjectUid(activeSlideUid);
          this.props.setActiveProjectText(activeSlideDetails);
        }
      );
    } else {
      let activeSlideDetails = this.state.allImages[this.state.currentSlide]
        .project.document.data.title;
      let activeSlideUid = this.state.allImages[this.state.currentSlide].project
        .document.uid;

      this.props.setActiveProjectUid(activeSlideUid);
      this.props.setActiveProjectText(activeSlideDetails);
    }
  };

  render() {
    const galleryImages = this.props.images.map((image, index) => {
      if (image.project.document.data.body3[0].slice_type === "image") {
        return (
          <ImageContainer className="image" key={`homepage_gallery_${index}`}>
            <ThreeTwo
              image={image.project.document.data.body3[0].primary.image.url}
              alt={image.project.document.data.body3[0].primary.image.alt}
              lazy={false}
              size={800}
              padding={`${
                image.project.document.data.body3[0].primary.padding !== null
                  ? image.project.document.data.body3[0].primary.padding
                  : `20`
              }px`}
            />
          </ImageContainer>
        );
      }

      if (
        image.project.document.data.body3[0].slice_type === "full_bleed_gallery"
      ) {
        const imageSrc = image.project.document.data.body3[0].primary.image.url;
        const alt = image.project.document.data.body3[0].primary.image.alt;
        return (
          <FullBleedImage
            key={`homepage_gallery_${index}`}
            className="full_bleed_gallery"
            src={imageSrc}
            // srcSet={`${imageSrc}&h=${1200}&crop=entropy&q=80 1x,
            // ${imageSrc}&h=${1200}&crop=entropy&dpr=2&q=80 2x,
            // ${imageSrc}&h=${1200}&crop=entropy&dpr=3&q=80 3x`}
            // src={`${imageSrc}&h=${1200}&crop=entropy&q=80`}
            alt={alt}
          />
        );
      }

      if (
        image.project.document.data.body3[0].slice_type === "bg_color_gallery"
      ) {
        return (
          <ImageContainer
            className="bg_color_gallery"
            key={`homepage_gallery_${index}`}
            backgroundColor={
              image.project.document.data.body3[0].primary.background_color
            }
          >
            <ThreeTwo
              image={image.project.document.data.body3[0].primary.image.url}
              alt={image.project.document.data.body3[0].primary.image.alt}
              lazy={false}
              size={800}
              padding={`${
                image.project.document.data.body3[0].primary.padding !== null
                  ? image.project.document.data.body3[0].primary.padding
                  : `20`
              }px`}
            />
          </ImageContainer>
        );
      }

      if (
        image.project.document.data.body3[0].slice_type ===
        "gallery_with_background_image"
      ) {
        const imageSrc =
          image.project.document.data.body3[0].primary.background_image.url;
        return (
          <FullBleedImageContainer
            key={`homepage_gallery_${index}`}
            className="gallery_with_background_image"
          >
            <ThreeTwo
              image={image.project.document.data.body3[0].primary.image.url}
              alt={image.project.document.data.body3[0].primary.image.alt}
              lazy={false}
              size={800}
              padding={`${
                image.project.document.data.body3[0].primary.padding !== null
                  ? image.project.document.data.body3[0].primary.padding
                  : `20`
              }px`}
            />

            <FullBleedImage
              //   srcSet={`${imageSrc}&h=${1200}&crop=entropy&q=80 1x,
              // ${imageSrc}&h=${1200}&crop=entropy&dpr=2&q=80 2x,
              // ${imageSrc}&h=${1200}&crop=entropy&dpr=3&q=80 3x`}
              //   src={`${imageSrc}&h=${1200}&crop=entropy&q=80`}
              src={imageSrc}
            />
          </FullBleedImageContainer>
        );
      }

      if (
        image.project.document.data.body3[0].slice_type ===
        "image_with_video_bg"
      ) {
        return (
          <ImageContainer
            key={`homepage_gallery_${index}`}
            className="image_with_video_bg"
          >
            <ThreeTwo
              image={image.project.document.data.body3[0].primary.image.url}
              alt={image.project.document.data.body3[0].primary.image.alt}
              lazy={false}
              size={800}
              // padding={`20px`}
              padding={`${
                image.project.document.data.body3[0].primary.padding !== null
                  ? image.project.document.data.body3[0].primary.padding
                  : `20`
              }px`}
            />

            {image.project.document.data.body3[0].primary.video.url && (
              <Video loop autoPlay muted playsInline preload="none">
                <source
                  type="video/mp4"
                  src={image.project.document.data.body3[0].primary.video.url}
                />
              </Video>
            )}
          </ImageContainer>
        );
      }

      if (image.project.document.data.body3[0].slice_type === "video") {
        return (
          <VideoContainer
            key={`homepage_gallery_${index}`}
            className="video"
            padding={image.project.document.data.body3[0].primary.padding}
          >
            {image.project.document.data.body3[0].primary.video.url && (
              <video loop autoPlay muted playsInline preload="none">
                <source
                  type="video/mp4"
                  src={image.project.document.data.body3[0].primary.video.url}
                />
              </video>
            )}
          </VideoContainer>
        );
      }

      if (
        image.project.document.data.body3[0].slice_type === "full_bleed_video"
      ) {
        return (
          <ImageContainer
            key={`homepage_gallery_${index}`}
            className="full-bleed-video"
          >
            {image.project.document.data.body3[0].primary.video.url && (
              <Video loop autoPlay muted playsInline preload="none">
                <source
                  type="video/mp4"
                  src={image.project.document.data.body3[0].primary.video.url}
                />
              </Video>
            )}
          </ImageContainer>
        );
      }
    });

    return (
      <GalleryContainer opacity={this.props.opacity}>
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          infiniteLoop={true}
          useKeyboardArrows={true}
          className="image-gallery"
          selectedItem={this.state.currentSlide}
          onChange={this.updateCurrentSlide}
          transitionTime={this.state.transitionTime}
          autoPlay={true}
          stopOnHover={false}
          interval={7000}
        >
          {galleryImages}
        </Carousel>
      </GalleryContainer>
    );
  }
}

export default Gallery;
