import React from "react";
import styled from "styled-components";
import "array-flat-polyfill";

// Styles
import { linkColor } from "../styles/colors";

const Filters = styled.ul`
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &:hover li > button {
    color: ${linkColor};
  }

  &:hover li > button:hover {
    color: #000;
  }

  & li {
    list-style: none;
    padding-right: 15px;

    &:last-of-type {
      padding-right: 0;
    }
  }

  @media (max-width: 767px) {
    padding: 0 0 20px;

    justify-content: center;
  }
`;

const FilterButton = styled.button`
  color: ${props => (props.isActiveFilter ? "#000000" : linkColor)};

  // &:hover {
  //   color: #000;
  // }
`;

const FilterBar = ({ filters, activeFilter, changeFilter }) => {
  const allFilters = Array.from(
    new Set(
      filters
        .filter(
          filter =>
            filter.tag !== undefined &&
            filter.tag !== null &&
            filter.tag !== undefined
        )
        .map(filter => filter.tag)
        .flat()
    )
  ).map(filter => (
    <li key={`filter_${filter}`}>
      <FilterButton
        onClick={() => changeFilter(filter)}
        isActiveFilter={activeFilter === filter}
      >
        {filter}
      </FilterButton>
    </li>
  ));

  return <Filters>{allFilters}</Filters>;
};

export default FilterBar;
