import React from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "./carousel.css";

// Check is mobile
import isMobile from "ismobilejs";

// Components
import MobileImage from "../images/mobile-image";

const GalleryContainer = styled.div`
  position: relative;
  opacity: ${props => props.opacity};

  width: 100%;
  height: 100%;

  padding: 36px 0;

  & .image-gallery,
  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .slider,
  & .slide {
    height: 100%;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;
  }
`;

const ImageContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;

  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "transparent"};

  & img {
    object-fit: contain;
    padding: 100px;
  }
`;

const FullBleedImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  object-fit: cover !important;

  width: 100vw !important;
  height: 100%;
`;

class MobileGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      allImages: [],
      activeSlide: null,
      totalSlides: 1,
    };
  }

  componentDidMount(props) {
    const slideTotal = this.props.images.length;
    const isMobileDevice = isMobile(window.navigator.userAgent).any ? 150 : 0;

    this.setState(
      {
        allImages: this.props.images,
        totalSlides: slideTotal,
        transitionTime: isMobileDevice,
      },
      () => {
        let activeSlideDetails = this.state.allImages[this.state.currentSlide]
          .project.document.data.title;

        let activeSlideUid = this.state.allImages[this.state.currentSlide]
          .project.document.uid;

        this.props.setActiveProjectText(activeSlideDetails);
        this.props.setActiveProjectUid(activeSlideUid);
      }
    );
  }

  updateCurrentSlide = index => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState(
        {
          currentSlide: index,
        },
        () => {
          let activeSlideDetails = this.state.allImages[this.state.currentSlide]
            .project.document.data.title;
          let activeSlideUid = this.state.allImages[this.state.currentSlide]
            .project.document.uid;

          this.props.setActiveProjectUid(activeSlideUid);
          this.props.setActiveProjectText(activeSlideDetails);
        }
      );
    } else {
      let activeSlideDetails = this.state.allImages[this.state.currentSlide]
        .project.document.data.title;
      let activeSlideUid = this.state.allImages[this.state.currentSlide].project
        .document.uid;

      this.props.setActiveProjectUid(activeSlideUid);
      this.props.setActiveProjectText(activeSlideDetails);
    }
  };

  render() {
    const galleryImages = this.props.images.map((image, index) => {
      if (image.project.document.data.body4[0].slice_type === "image") {
        return (
          <ImageContainer className="image" key={`homepage_gallery_${index}`}>
            <MobileImage
              image={image.project.document.data.body4[0].primary.image.url}
              alt={image.project.document.data.body4[0].primary.image.alt}
              lazy={true}
              size={500}
              padding={`${
                image.project.document.data.body4[0].primary.padding !== null
                  ? image.project.document.data.body4[0].primary.padding
                  : `36`
              }px`}
            />
          </ImageContainer>
        );
      }

      if (
        image.project.document.data.body4[0].slice_type ===
        "image_with_bg_color"
      ) {
        return (
          <ImageContainer
            className="image_with_bg_color"
            key={`homepage_gallery_${index}`}
            backgroundColor={
              image.project.document.data.body4[0].primary.background_color
            }
          >
            <MobileImage
              image={image.project.document.data.body4[0].primary.image.url}
              alt={image.project.document.data.body4[0].primary.image.alt}
              lazy={true}
              size={800}
              padding={`${
                image.project.document.data.body4[0].primary.padding !== null
                  ? image.project.document.data.body4[0].primary.padding
                  : `36`
              }px`}
            />
          </ImageContainer>
        );
      }

      if (
        image.project.document.data.body4[0].slice_type === "full_bleed_image"
      ) {
        const imageSrc = image.project.document.data.body4[0].primary.image.url;
        const alt = image.project.document.data.body4[0].primary.image.alt;
        return (
          <FullBleedImage
            key={`homepage_gallery_${index}`}
            className="full_bleed_gallery"
            src={imageSrc}
            // srcSet={`${imageSrc}&h=${1200}&crop=entropy&q=80 1x,
            // ${imageSrc}&h=${1200}&crop=entropy&dpr=2&q=80 2x,
            // ${imageSrc}&h=${1200}&crop=entropy&dpr=3&q=80 3x`}
            // src={`${imageSrc}&h=${1200}&crop=entropy&q=80`}
            alt={alt}
            lazy={true}
            // loading="lazy"
          />
        );
      }
    });

    return (
      <GalleryContainer opacity={this.props.opacity}>
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          infiniteLoop={true}
          useKeyboardArrows={true}
          className="image-gallery"
          selectedItem={this.state.currentSlide}
          onChange={this.updateCurrentSlide}
          autoPlay={true}
          stopOnHover={false}
          interval={7000}
          transitionTime={this.state.transitionTime}
        >
          {galleryImages}
        </Carousel>
      </GalleryContainer>
    );
  }
}

export default MobileGallery;
