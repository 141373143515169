import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Media from "react-media";

// Components
import ThreeTwo from "../images/three-two";
import FilterBar from "./filter-bar";
import HomepageMobileMenu from "../navigation/homepage-mobile-menu";

//Styles
import { borderColor, linkColor } from "../styles/colors";

const Row = styled.div`
  display: grid;
  grid-gap: 25px;

  padding: 0 0 40px 0;

  @media (min-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 1399px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  &:hover > .project h3 {
    color: ${linkColor};
  }

  // &:hover > .project {
  //   opacity: 0.5;
  // }

  &:hover > .project:hover h3 {
    color: #000;
  }

  // &:hover > .project:hover {
  //   opacity: 1;
  // }

  @media (hover: none) and (pointer: coarse) {
    &:hover > .project h3,
    &:hover > .project,
    &:hover > .project:hover,
    &:hover > .project:hover {
      opacity: 1;
      color: #000;
    }
  }
`;

const SlideoutContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  height: 100%;

  background-color: #fff;

  transform: translateY(
    ${props => (props.isSlideoutOpen ? "36px" : "calc(100% - 37px)")}
  );
  transition: 500ms transform cubic-bezier(0.67, 0.41, 0.37, 0.63);

  display: flex;
  // min-height: calc(100vh - 70px);
  min-height: 100%;
  flex-direction: column;

  z-index: 300;

  @media (max-width: 767px) {
    transform: translateY(
      ${props => (props.isSlideoutOpen ? "0" : "calc(100% - 38px)")}
    );
  }
`;

const SlideoutBar = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  line-height: 1;

  border-top: 1px solid ${borderColor};

  padding: 12px 20px 9px 20px;

  z-index: 1000;
  height: initial;

  @media (max-width: 767px) {
    padding: 12px 10px 9px 10px;

    border-top: ${props =>
      props.isSlideoutOpen ? "none" : `1px solid ${borderColor}`}};
  }
`;

const SlideoutTrigger = styled.button`
  line-height: 1;

  &:hover {
    color: ${linkColor};
  }
`;

const ActiveText = styled.div`
  & p,
  h3 {
    font-size: 15px;
    margin: 0;
  }
`;

const ActiveUID = styled.p`
  margin: 0;
  width: 90px;

  text-align: right;

  &:hover {
    color: ${linkColor};
  }

  @media (max-width: 767px) {
    width: auto;
  }
`;

const Projects = styled.div`
  padding: 20px;

  flex: 1;
  z-index: 1;

  overflow-y: auto;

  @media (max-width: 500px) {
    padding: 20px 10px;
  }
`;

const Project = styled.div`
  position: relative;
  // overflow: hidden;

  & h3 {
    font-size: 15px;
    text-align: center;

    margin-top: 5px;
  }

  &:hover h3 {
    color: ${linkColor};
  }
`;

const Spacer = styled.div`
  width: 90px;
`;

const Slideout = ({
  projects,
  filters,
  isSlideoutOpen,
  activeProjectText,
  activeProjectUid,
  onClick,
  menuLinks,
  menuText,
}) => {
  const [activeFilter, changeFilter] = useState("All");
  const [windowWidth, setWindowWidth] = useState(768);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setWindowWidth(window.innerWidth);
    }
  }, []);

  const projectsGrid = projects
    .filter(project => {
      if (activeFilter === "All") {
        return project;
      }

      if (activeFilter !== "All") {
        return project.project.document.tags.includes(activeFilter);
      }
    })
    .map((project, index) => (
      <div key={project.project.document.prismicId} className="project">
        <Project>
          <Link to={`/projects/${project.project.document.uid}`}>
            {project.project.document.data.body3[0].slice_type === "image" && (
              <ThreeTwo
                className="image"
                key={index}
                image={project.project.document.data.body3[0].primary.image.url}
                alt={project.project.document.data.body3[0].primary.image.alt}
                lazy={false}
                size={800}
              />
            )}

            {project.project.document.data.body3[0].slice_type ===
              "full_bleed_gallery" && (
              <ThreeTwo
                className="full_bleed_gallery"
                key={index}
                image={project.project.document.data.body3[0].primary.image.url}
                alt={project.project.document.data.body3[0].primary.image.alt}
                lazy={false}
                size={800}
                objectFit={`cover !important`}
              />
            )}

            {project.project.document.data.body3[0].slice_type ===
              "bg_color_gallery" && (
              <ThreeTwo
                className="bg_color_gallery"
                key={index}
                image={project.project.document.data.body3[0].primary.image.url}
                alt={project.project.document.data.body3[0].primary.image.alt}
                lazy={false}
                size={800}
                backgroundColor={
                  project.project.document.data.body3[0].primary
                    .background_color
                }
                padding={
                  project.project.document.data.body3[0].primary
                    .background_color !== undefined
                    ? `20px 0`
                    : `0`
                }
                // objectFit={
                //   project.project.document.data.body3[0].primary.image
                //     .dimensions.height >
                //   project.project.document.data.body3[0].primary.image
                //     .dimensions.width
                //     ? "contain"
                //     : "cover"
                // }
                objectFit={`contain`}
              />
            )}

            {project.project.document.data.body3[0].slice_type ===
              "gallery_with_background_image" && (
              <ThreeTwo
                key={index}
                image={project.project.document.data.body3[0].primary.image.url}
                alt={project.project.document.data.body3[0].primary.image.alt}
                lazy={false}
                size={800}
                backgroundImage={
                  project.project.document.data.body3[0].primary
                    .background_image.url
                }
                padding={`20px 0`}
                // objectFit={
                //   project.project.document.data.body3[0].primary.image
                //     .dimensions.height >
                //   project.project.document.data.body3[0].primary.image
                //     .dimensions.width
                //     ? "contain"
                //     : "cover"
                // }
                objectFit={`contain`}
              />
            )}

            {project.project.document.data.body3[0].slice_type ===
              "image_with_video_bg" && (
              <ThreeTwo
                image={project.project.document.data.body3[0].primary.image.url}
                alt={project.project.document.data.body3[0].primary.image.alt}
                lazy={false}
                size={800}
                padding={`20px 0`}
                video={project.project.document.data.body3[0].primary.video.url}
                key={index}
                // objectFit={
                //   project.project.document.data.body3[0].primary.image
                //     .dimensions.height >
                //   project.project.document.data.body3[0].primary.image
                //     .dimensions.width
                //     ? "contain"
                //     : "cover"
                // }
                objectFit={`contain`}
              />
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: project.project.document.data.title.html,
              }}
            />
          </Link>
        </Project>
      </div>
    ));

  return (
    <SlideoutContainer isSlideoutOpen={isSlideoutOpen}>
      <SlideoutBar isSlideoutOpen={isSlideoutOpen}>
        <SlideoutTrigger onClick={() => onClick()}>
          Visual Index {isSlideoutOpen === false ? "+" : "–"}
        </SlideoutTrigger>

        <Media
          queries={{ medium: "(min-width: 769px)" }}
          defaultMatches={{ medium: windowWidth === 769 }}
          render={() => {
            if (isSlideoutOpen === true) {
              return (
                <>
                  <FilterBar
                    filters={filters}
                    activeFilter={activeFilter}
                    changeFilter={changeFilter}
                  />
                  <Spacer />
                </>
              );
            } else {
              return null;
            }
          }}
        />

        <Media
          queries={{ medium: "(min-width: 769px)" }}
          defaultMatches={{ medium: windowWidth === 769 }}
          render={() => {
            if (isSlideoutOpen === false) {
              return (
                <ActiveText>
                  {activeProjectText !== null && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: activeProjectText.html,
                      }}
                    />
                  )}
                </ActiveText>
              );
            } else {
              return null;
            }
          }}
        />

        {isSlideoutOpen === false && (
          <ActiveUID>
            {activeProjectUid !== null && (
              <Link to={`/projects/${activeProjectUid}`}>View Project</Link>
            )}
          </ActiveUID>
        )}

        <Media
          queries={{ medium: "(max-width: 768px)" }}
          defaultMatches={{ medium: windowWidth === 768 }}
          render={() => {
            if (isSlideoutOpen === true) {
              return (
                <HomepageMobileMenu
                  hideLogo={true}
                  links={menuLinks}
                  text={menuText}
                  location={`/`}
                  filters={null}
                  projectsActiveFilter={"All"}
                  setProjectsActiveFilter={"All"}
                />
              );
            } else {
              return null;
            }
          }}
        />
      </SlideoutBar>

      <Projects>
        <Media
          queries={{ medium: "(max-width: 768px)" }}
          defaultMatches={{ medium: windowWidth === 768 }}
          render={() => {
            if (isSlideoutOpen === true) {
              return (
                <FilterBar
                  filters={filters}
                  activeFilter={activeFilter}
                  changeFilter={changeFilter}
                />
              );
            } else {
              return null;
            }
          }}
        />

        <Row>{projectsGrid}</Row>
      </Projects>
    </SlideoutContainer>
  );
};

export default Slideout;
