import React, { useState, useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Media from "react-media";
import Div100vh from "react-div-100vh";
import { mergePrismicPreviewData } from "gatsby-source-prismic";
import { Helmet } from "react-helmet";

// Components
import Slideout from "../components/homepage/slideout";
import Gallery from "../components/images/gallery";
import MobileGallery from "../components/images/mobile-gallery";

const Page = styled.div`
  padding: 0;

  height: 100%;
`;

// Returns true if we're in a browser, false otherwise. This will help guard
// against SSR issues when building the site.
const IS_BROWSER = typeof window !== "undefined";

const Index = ({ data: staticData }) => {
  const data = useMemo(() => {
    // If we're not in a browser (i.e. we're in SSR) or preview data has not been
    // set, use the non-preview static data.
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData;

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    });
  }, [staticData]);

  const [isSlideoutOpen, setIsSlideoutOpen] = useState(false);
  const [activeProjectText, setActiveProjectText] = useState(null);
  const [activeProjectUid, setActiveProjectUid] = useState(null);
  const [windowWidth, setWindowWidth] = useState(820);
  const [isWindowReady, setWindowReady] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setWindowWidth(window.innerWidth);
    }

    setWindowReady(true);
  }, []);

  const projects = data.prismicHomepage.data.projects
    .filter(content => content.project !== null)
    .filter(
      project =>
        project.project.document.data.body3[0] !== undefined &&
        project.project.document.data.body4[0] !== undefined
    );

  if (projects === null) {
    return null;
  } else {
    return (
      <>
        <Helmet
          meta={[
            {
              name: "og:url",
              content: `https://www.wolfehall.com/`,
            },
          ]}
        />
        <Div100vh>
          <Page>
            <Media
              queries={{ medium: "(min-width: 821px)" }}
              defaultMatches={{ medium: windowWidth === 821 }}
              render={() => (
                <Gallery
                  images={projects}
                  setActiveProjectText={setActiveProjectText}
                  setActiveProjectUid={setActiveProjectUid}
                  opacity={isWindowReady === true ? `1` : 0}
                />
              )}
            />
            <Media
              queries={{ medium: "(max-width: 820px)" }}
              defaultMatches={{ medium: windowWidth === 820 }}
              render={() => (
                <MobileGallery
                  images={projects}
                  setActiveProjectText={setActiveProjectText}
                  setActiveProjectUid={setActiveProjectUid}
                  opacity={isWindowReady === true ? `1` : 0}
                />
              )}
            />
            <Slideout
              projects={projects}
              filters={data.prismicProjectsMenu.data.items}
              isSlideoutOpen={isSlideoutOpen}
              activeProjectText={activeProjectText}
              activeProjectUid={activeProjectUid}
              onClick={() => setIsSlideoutOpen(!isSlideoutOpen)}
              menuLinks={data.site.siteMetadata.menuLinks}
              menuText={data.prismicMenu.data.mobile_menu_text}
            />
          </Page>
        </Div100vh>
      </>
    );
  }
};

export default Index;

export const pageQuery = graphql`
  query Homepage {
    prismicProjectsMenu {
      data {
        items {
          tag
        }
      }
    }
    prismicMenu {
      data {
        mobile_menu_text {
          html
        }
      }
    }
    site {
      siteMetadata {
        menuLinks {
          link
          name
        }
      }
    }
    prismicHomepage {
      data {
        projects {
          project {
            document {
              ... on PrismicProject {
                prismicId
                tags
                data {
                  title {
                    html
                    text
                  }
                  body4 {
                    ... on PrismicProjectBody4Image {
                      id
                      slice_type
                      primary {
                        image {
                          url
                          dimensions {
                            height
                            width
                          }
                          alt
                        }
                        padding
                      }
                    }
                    ... on PrismicProjectBody4FullBleedImage {
                      id
                      primary {
                        image {
                          url
                          dimensions {
                            height
                            width
                          }
                          alt
                        }
                      }
                      slice_type
                    }
                    ... on PrismicProjectBody4ImageWithBgColor {
                      id
                      slice_type
                      primary {
                        background_color
                        padding
                        image {
                          url
                          dimensions {
                            height
                            width
                          }
                        }
                      }
                    }
                  }
                  body3 {
                    ... on PrismicProjectBody3Image {
                      slice_type
                      primary {
                        image {
                          url
                          dimensions {
                            height
                            width
                          }
                          alt
                        }
                        padding
                      }
                      id
                    }
                    ... on PrismicProjectBody3FullBleedGallery {
                      slice_type
                      primary {
                        image {
                          url
                          dimensions {
                            height
                            width
                          }
                          alt
                        }
                      }
                      id
                    }
                    ... on PrismicProjectBody3GalleryWithBackgroundImage {
                      id
                      slice_type
                      primary {
                        image {
                          alt
                          dimensions {
                            height
                            width
                          }
                          url
                        }
                        padding
                        background_image {
                          url
                          alt
                        }
                      }
                    }
                    ... on PrismicProjectBody3BgColorGallery {
                      id
                      slice_type
                      primary {
                        background_color
                        padding
                        image {
                          alt
                          url
                          dimensions {
                            height
                            width
                          }
                        }
                      }
                    }
                    ... on PrismicProjectBody3ImageWithVideoBg {
                      id
                      slice_type
                      primary {
                        padding
                        image {
                          url
                          dimensions {
                            height
                            width
                          }
                          alt
                        }
                        video {
                          url
                        }
                      }
                    }
                    ... on PrismicProjectBody3FullBleedVideo {
                      id
                      primary {
                        video {
                          url
                        }
                      }
                      slice_type
                    }
                    ... on PrismicProjectBody3Video {
                      id
                      slice_type
                      primary {
                        padding
                        video {
                          url
                        }
                      }
                    }
                  }
                }
                uid
              }
            }
          }
        }
      }
    }
  }
`;
