import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useState } from "react";
import "array-flat-polyfill";

const Menu = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  padding: 12px 10px 9px 10px;

  height: auto;
  width: ${props => props.width};
  z-index: 10000;

  background: #fff;

  & ul,
  & li {
    list-style: none;
    margin: 0;
  }

  & ul {
    display: flex;
    flex-direction: row;

    padding: 0;
    margin: 0 auto 0 0;
  }

  & li {
    padding-right: 20px;
    &:last-of-type {
      padding-right: 0;
    }
  }
`;

const StudioText = styled.div`
  font-size: 16px;
  line-height: 21px;

  letter-spacing: 0.05px;

  @media (max-width: 500px) {
    padding: 0 50px 0 0;

    & p {
      margin: 20px 0 10px 0;
    }
  }
`;

const MenuToggle = styled.button`
  line-height: 1;

  padding: ${props => (props.padding ? props.padding : "")};
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;

  width: 100%;

  & > a,
  & > ul {
    margin: ${props => props.adjustLogoMargin};
  }
`;

const HomepageMobileMenu = ({ links, text }) => {
  const [isMenuOpen, toggleMenuOpen] = useState(false);

  return (
    <Menu width={isMenuOpen ? `100%` : `auto`}>
      <Nav>
        {isMenuOpen === true && (
          <ul>
            {links.map(link => (
              <li key={link.name}>
                <Link to={link.link} onClick={() => toggleMenuOpen(false)}>
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        )}

        <MenuToggle onClick={() => toggleMenuOpen(!isMenuOpen)}>
          Menu {isMenuOpen ? "–" : "+"}
        </MenuToggle>
      </Nav>

      {isMenuOpen === true && (
        <div>
          <StudioText
            dangerouslySetInnerHTML={{
              __html: text.html,
            }}
          />
        </div>
      )}
    </Menu>
  );
};

export default HomepageMobileMenu;
